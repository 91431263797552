export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const BASE = process.env.REACT_APP_BASE;
export const SEND_OTP_API = `${BASE}/auth/send-otp-for-auth`;
export const VERIFY_OTP_API = `${BASE}/auth/validate-otp-for-auth`;
export const EVENT_BOOKING_DETAILS_API = `${BASE}/specific-event/event-booking-details`;
export const EVENT_INFO_DETAILS_API = `${BASE}/event/fetch-event-info?eventCode=`;
export const NEW_USER = `${BASE}/user/update-profile`;
export const SEAT_DETAILS = `${BASE}/specific-event/fetch-seating-arrangement?eventId=`;
export const SEAT_VERIFY = `${BASE}/event-payment/v5/process-event-payment`;
export const SINGLE_TICKET = `${BASE}/specific-event/fetch-single-ticket?bookingId=`;
export const PAYMENT = `${BASE}/event-payment/v1/fetch-payment-status?transactionId=`;
export const CATEGORY_INFO = `${BASE}/event/v1/category-name?category=`;
export const EVENT_BY_CATEGORY = `${BASE}/event/fetch-events-category-by-page-guest`;
export const EVENT_CATEGORY = `${BASE}/event/fetch-category`;
export const CATEGORY_BY_EVENT = `${BASE}/event/fetch-events-by-category-name-by-page-guest`;
export const APPLY_COUPON = `${BASE}/event/apply-coupon`;
export const EVENT_GEOLOCATION = `${BASE}/event/fetch-events-by-page-guest-by-location`;
export const EVENT_SLIDER = `${BASE}/event/fetch-events-slider-by-page-guest`;
export const LIST_EVENT_DATA = `${BASE}/event/submit-event-request-event-site`;
export const UPLOAD_IMAGE = `${BASE}/aws-util/generate-presigned-url-by-form`;
export const EVENT_SEARCH = `${BASE}/event/v1/searchglobalco`;
export const ACTIVE_DATES = `${BASE}/event/fetch-active-dates`;
export const ACTIVE_TICKET = `${BASE}/event/fetch-ticket-by-date`;
export const FETCH_USER = `${BASE}/user/my-profile`;
export const UPDATE_PROFILE = `${BASE}/user/update-profile`;
export const UPLOAD_PROFILE_PIC = `${BASE}/aws-util/generate-presigned-url-for-profile-pic`;
export const EVENT_TRENDING = `${BASE}/event/fetch-events-trending-by-page-guest`;
export const EVENT_ALLEXPIRED = `${BASE}/event/fetch-events-expired-by-page-guest`;
export const EVENT_LIVE = `${BASE}/event/fetch-all-live-events-by-page-guest`;
export const EVENT_EXPIRED = `${BASE}/event/fetch-all-expired-events-by-page-guest`;
export const PASSES_ALL_CHECK = `${BASE}/event-payment/v1/passes-all-check`;
export const PASSES_ALL_CHECK_D2C = `${BASE}/event-payment/v1/passes-all-check-d2c`;
export const SEAT_VERIFY_D2C = `${BASE}/event-payment/v5/process-event-payment-d2c`;

export const USER_PROFILE = `${BASE}/user/my-profile`;
export const RAZORPAY_SCRIPT = process.env.REACT_APP_RAZORPAY_SCRIPT;
export const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY;
export const URL_KEY = process.env.REACT_APP_URL_KEY;
export const URL_IV = process.env.REACT_APP_URL_IV;
export const WEB_TYPE = 'fydo-customer-app';
