import { isEmpty } from 'lodash';
import {
  NEW_USER,
  SEND_OTP_API,
  VERIFY_OTP_API,
  EVENT_BOOKING_DETAILS_API,
  SEAT_DETAILS,
  SEAT_VERIFY,
  PAYMENT,
  SINGLE_TICKET,
  USER_PROFILE,
} from '../../config/endPoints';
import instance from '../../utils/instance';
import {
  CLEAR_ERRORS,
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  LOGOUT,
  NEW_USER_REQUEST,
  NEW_USER_FAIL,
  AUTHENTICATED,
  NEW_USER_SUCCESS,
  EVENT_FAIL,
  EVENT_REQUEST,
  EVENT_SUCCESS,
  SEAT_DETAIL_FAIL,
  SEAT_DETAIL_REQUEST,
  SEAT_DETAIL_SUCCESS,
  SEAT_VERIFY_FAIL,
  SEAT_VERIFY_REQUEST,
  SEAT_VERIFY_SUCCESS,
  PAYMENT_FAIL,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  SINGLE_TICKET_REQUEST,
  SINGLE_TICKET_SUCCESS,
  SINGLE_TICKET_FAIL,
  SEAT_VERIFY_STATUS_FALSE,
  USER_PROFILE_REQUEST,
  USER_PROFILE_FAIL,
  USER_PROFILE_SUCCESS,
  SET_ERRORS,
  SET_ORDER_ID,
  CLEAR_ORDER_ID,
} from '../constants';

// Send OTP
export const sendOtp = (userData) => async (dispatch) => {
  try {
    dispatch({ type: SEND_OTP_REQUEST });
    const data = await instance({
      method: 'POST',
      url: SEND_OTP_API,
      data: userData,
    });
    if (data.status === 201) {
      dispatch({
        type: SEND_OTP_SUCCESS,
        payload: data?.data?.otpId,
      });
    }
  } catch (error) {
    dispatch({
      type: SEND_OTP_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'SEND_OTP_ERROR',
      },
    });
  }
};

export const verfiyOtp = (userData) => async (dispatch) => {
  try {
    dispatch({ type: VERIFY_OTP_REQUEST });
    const data = await instance({
      method: 'POST',
      url: VERIFY_OTP_API,
      data: userData,
    });
    if (data.status === 201) {
      if (typeof window !== 'undefined') {
        localStorage.setItem('token', data?.data?.accessToken);
      }
      dispatch({
        type: VERIFY_OTP_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: VERIFY_OTP_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'VERFIY_OTP_ERROR',
      },
    });
  }
};

export const newUser = (userData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_USER_REQUEST });
    const data = await instance({
      method: 'POST',
      url: NEW_USER,
      data: userData,
    });
    if (data.status === 201) {
      dispatch({
        type: NEW_USER_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    dispatch({
      type: NEW_USER_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'NEW_USER_ERROR',
      },
    });
  }
};

export const getEvent =
  ({ eventId, skip }) =>
  async (dispatch) => {
    const queryParams = [`limit=10`];
    if (eventId) queryParams.push(`eventId=${eventId}`);
    if (skip) queryParams.push(`skip=${skip}`);
    const newParams = queryParams.join('&');
    try {
      dispatch({ type: EVENT_REQUEST });
      const data = await instance({
        method: 'GET',
        url: EVENT_BOOKING_DETAILS_API + `?${newParams}`,
      });

      dispatch({
        type: EVENT_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_FAIL,
        payload: {
          message: error?.response?.data?.message,
          code: 'EVENT_ERROR',
        },
      });
    }
  };
export const getSeatDetail = (props) => async (dispatch) => {
  const { eventId, seatPosition } = props;
  try {
    dispatch({ type: SEAT_DETAIL_REQUEST });
    const data = await instance({
      method: 'GET',
      url: SEAT_DETAILS + eventId + `&side=${seatPosition}`,
    });
    dispatch({
      type: SEAT_DETAIL_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: SEAT_DETAIL_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'SEAT_DETAIL_ERROR',
      },
    });
  }
};

export const setSeatVerify = (seatVerify) => async (dispatch) => {
  try {
    dispatch({ type: SEAT_VERIFY_REQUEST });
    const data = await instance({
      method: 'POST',
      url: SEAT_VERIFY,
      data: seatVerify,
    });
    dispatch({
      type: SEAT_VERIFY_SUCCESS,
      payload: data.data,
    });
    Promise.resolve();
    return data.data;
  } catch (error) {
    dispatch({
      type: SEAT_VERIFY_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'SEAT_VERIFY_ERROR',
      },
    });
  }
};
export const payment = (transactionId) => async (dispatch) => {
  try {
    dispatch({ type: PAYMENT_REQUEST });
    const data = await instance({
      method: 'GET',
      url: PAYMENT + transactionId,
    });
    dispatch({ type: PAYMENT_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: PAYMENT_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'PAYMENT_ERROR',
      },
    });
  }
};
export const getSingleBooking = (bookingId) => async (dispatch) => {
  try {
    dispatch({ type: SINGLE_TICKET_REQUEST });
    const data = await instance({
      method: 'GET',
      url: SINGLE_TICKET + bookingId,
    });
    dispatch({ type: SINGLE_TICKET_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: SINGLE_TICKET_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'SINGLE_TICKET_ERROR',
      },
    });
  }
};

export const getUserProfile = (token) => async (dispatch) => {
  try {
    dispatch({ type: USER_PROFILE_REQUEST });
    const data = await instance({
      method: 'GET',
      url: USER_PROFILE,
    });
    if (!isEmpty(data.data) && token) {
      dispatch({
        type: USER_PROFILE_SUCCESS,
        payload: { accessToken: token, ...data.data },
      });
    }
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: {
        message: error?.response?.data?.message,
        code: 'USER_PROFILE_ERROR',
      },
    });
  }
};
export const authenticated = () => async (dispatch) => {
  dispatch({
    type: AUTHENTICATED,
  });
};
export const seatVerifyStatusFalse = () => async (dispatch) => {
  dispatch({
    type: SEAT_VERIFY_STATUS_FALSE,
  });
};
export const setOrderId = (orderId) => async (dispatch) => {
  dispatch({
    type: SET_ORDER_ID,
    payload: orderId,
  });
};

// Action creator to clear the order ID
export const clearOrderId = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ORDER_ID,
  });
};
// Logout
export const logOut = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
// Set Errors
export const setErrors = (message) => async (dispatch) => {
  dispatch({
    type: SET_ERRORS,
    payload: message,
  });
};
