import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Login from '../components/login';
import { clearErrors } from '../redux/actions/userActions';
import ProtectedRoute from './ProtectedRoute';
import PaymentStatusPage from '../pages/PaymentStatusPage';
import Booking from '../pages/Booking';
import { useCodeHandle } from '../utils/useCodeHandle';
import { useWebViewHandle } from '../utils/useWebViewHandle';
import { WEB_TYPE } from '../config/endPoints';
import { CTFooter } from '../components/common';
import Navbar from '../components/Navbar';
import Merchant from '../pages/Merchant';
import CategoryPage from '../pages/CategoryPage';
import SearchPage from '../pages/SearchPage';
import AllEvents from '../pages/AllEvents';
import Home from '../pages/Home';
import CampusAllEvents from '../pages/CampusAllEvents';
import LiveShowsAllEvents from '../pages/LiveShowsAllEvents';
import EventForm from '../components/Merchant/EventForm';
import HoliAllEvents from '../pages/HoliAllEvents';
import Product from '../pages/Product';
import Success from '../pages/Success';

const PageNotFound = React.lazy(() => import(/* webpackChunkName: "PageNotFound" */ '../pages/PageNotFound'));
const ErrorPage = React.lazy(() => import(/* webpackChunkName: "ErrorPage" */ '../pages/ErrorPage'));
const ViewBookingPage = React.lazy(() => import(/* webpackChunkName: "ErrorPage" */ '../pages/ViewBookingPage'));
// const PaymentStatusPage = React.lazy(() =>
//   import(
//     /* webpackChunkName: "PaymentStatusPage" */ '../pages/PaymentStatusPage'
//   ),
// )
// const Profile = React.lazy(() => import(/* webpackChunkName: "MyBookingPage" */ '../pages/Profile'));

const MyBookingPage = React.lazy(() => import(/* webpackChunkName: "MyBookingPage" */ '../pages/MyBookingPage'));
// const Booking = React.lazy(() =>
//   import(/* webpackChunkName: "Booking" */ '../pages/Booking'),
// )
const SeatLayout = React.lazy(() => import(/* webpackChunkName: "SeatLayout" */ '../pages/SeatLayout'));
const SingleTicketPage = React.lazy(() =>
  import(/* webpackChunkName: "SingleTicketPage" */ '../pages/SingleTicketPage'),
);
const Router = ({ error, clearErrors, userError }) => {
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const history = useNavigate();

  // const appWebViewMatch = matchPath('/:code/:token/:type', pathname)
  useCodeHandle();
  const webType = useWebViewHandle();
  // const isHeaderFooterShow = 'true';
  const isHeaderFooterShow =
    !['/test/curiobit', '/test/curiobit/paymentstatus', new RegExp('^/test/curiobit/paymentstatus/[^/]+$')].some(
      (route) => location.pathname.match(route),
    ) && webType !== WEB_TYPE;

  useEffect(() => {
    if (!isEmpty(error) && error?.code === 'EVENT_INFO_ERROR') {
      history('/error');
      clearErrors();
    }
  }, [error, history, clearErrors]);
  useEffect(() => {
    if (!isEmpty(userError) && userError?.code === 'USER_PROFILE_ERROR') {
      history('/error');
      clearErrors();
    }
  }, [userError, history, clearErrors]);
  useEffect(() => {
    if (!isEmpty(userError) && userError?.code === 'USER_UNAUTHORIZED_ERROR') {
      setOpen(true);
      clearErrors();
    }
  }, [userError, history, clearErrors]);
  return (
    <>
      {isHeaderFooterShow && webType !== WEB_TYPE && <Navbar handleOpen={handleOpen} />}
      <div className={isHeaderFooterShow && webType !== WEB_TYPE ? 'mt-12 md:mt-14 lg:mt-[90px]' : ''}>
        <Routes>
          <Route exact path="/" element={<Home handleOpen={handleOpen} />} />
          <Route exact path="/organiser" element={<Merchant handleOpen={handleOpen} />} />
          <Route exact path="/organiser/list-your-event" element={<EventForm handleOpen={handleOpen} />} />

          <Route exact path="/test/curiobit" element={<Product handleOpen={handleOpen} />} />
          <Route exact path="/test/curiobit/paymentstatus/:orderId" element={<Success handleOpen={handleOpen} />} />

          <Route exact path="/:code" element={<Booking handleOpen={handleOpen} />} />
          {/* Mobile Route */}
          <Route exact path="/:code/:token/:type" element={<Booking handleOpen={handleOpen} />} />
          <Route exact path="/ticket/:id" element={<SingleTicketPage />} />
          <Route exact path="/category/:categoryId" element={<CategoryPage handleOpen={handleOpen} />} />
          <Route exact path="/search/:queryString" element={<SearchPage handleOpen={handleOpen} />} />
          <Route exact path="/allevents" element={<AllEvents handleOpen={handleOpen} />} />
          <Route exact path="/allevents/campus" element={<CampusAllEvents handleOpen={handleOpen} />} />
          <Route exact path="/allevents/liveshows" element={<LiveShowsAllEvents handleOpen={handleOpen} />} />
          <Route exact path="/allevents/holi" element={<HoliAllEvents handleOpen={handleOpen} />} />
          <Route element={<ProtectedRoute handleOpen={handleOpen} />}>
            {/* <Route path="/profile" element={<Profile />} /> */}
            <Route path="/mybooking" element={<ViewBookingPage />} />
            <Route path="/:code/seatlayout" element={<SeatLayout />} />
            <Route path="/:code/mybooking" element={<MyBookingPage />} />
            <Route path="/:code/paymentstatus" element={<PaymentStatusPage />} />
          </Route>
          <Route exact path="/error" element={<ErrorPage />} />
          <Route exact path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </div>
      {/* <Location isOpen={isOpen} handleClose={handleClose}/> */}
      <Login isOpen={isOpen} handleClose={handleClose} />
      {isHeaderFooterShow && <CTFooter />}
    </>
  );
};

const mapStateToProps = (state) => ({
  error: get(state, 'event.error', {}),
  userError: get(state, 'auth.error', {}),
  eventInfo: get(state, 'event.eventInfo', {}),
});
export default connect(mapStateToProps, { clearErrors })(Router);
