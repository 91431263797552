import {
  CLEAR_ERRORS,
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  LOGOUT,
  AUTHENTICATED,
  NEW_USER_REQUEST,
  NEW_USER_FAIL,
  NEW_USER_SUCCESS,
  EVENT_REQUEST,
  EVENT_SUCCESS,
  EVENT_FAIL,
  SEAT_DETAIL_REQUEST,
  SEAT_DETAIL_SUCCESS,
  SEAT_DETAIL_FAIL,
  SEAT_VERIFY_REQUEST,
  SEAT_VERIFY_SUCCESS,
  SEAT_VERIFY_FAIL,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_FAIL,
  SINGLE_TICKET_REQUEST,
  SINGLE_TICKET_SUCCESS,
  SINGLE_TICKET_FAIL,
  SEAT_VERIFY_STATUS_FALSE,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  SET_ORDER_ID,
  CLEAR_ORDER_ID,
  SET_ERRORS,
} from '../constants';
import jwtDecode from 'jwt-decode';
import { isEmpty } from 'lodash';
const initState = {
  loading: false,
  seatLoading: false,
  seatVerifyLoading: false,
  paymentLoading: false,
  token: '',
  user: '',
  otpId: null,
  otpSuccess: false,
  isAuthenticated: false,
  seatVerifySuccess: false,
  event: null,
  seat: null,
  seatVerify: null,
  payment: null,
  myBooking: null,
  singleTicket: null,
  viewTicket: null,
  orderId: null,
  error: {},
};

const verifyToken = (token) => {
  try {
    if (!token) {
      throw new Error('Token is missing');
    }
    const decodeToken = jwtDecode(token);
    const expiresIn = new Date(!isEmpty(decodeToken) && decodeToken.exp * 1000);

    if (new Date() > expiresIn) {
      localStorage.removeItem('token');
      initState.token = null;
      return null;
    } else {
      return decodeToken;
    }
  } catch (error) {
    console.error('Token verification failed:', error);
    localStorage.removeItem('token');
    initState.token = null;
    return null;
  }
};
// const verifyToken = (token) => {
//   try {
//     const decodeToken = jwtDecode(token)
//     const expiresIn = new Date(!isEmpty(decodeToken) && decodeToken.exp * 1000)
//     if (new Date() > expiresIn) {
//       localStorage.removeItem('token')
//       initState.token = null
//       return null
//     } else {
//       return token
//     }
//   } catch (error) {
//     if (error.name === 'InvalidTokenError') {
//       localStorage.removeItem('token')
//     }
//   }
// }
// const token = localStorage.getItem('token')
// if (token) {
//   const decoded = verifyToken(token)
//   if (!isEmpty(decoded) && decoded) {
//     initState.token = token
//   }
// }
// Auth reducer
export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case SEND_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        otpId: action.payload,
        otpSuccess: true,
        error: {},
      };
    case SEND_OTP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        otpSuccess: false,
      };
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        token: verifyToken(action.payload?.accessToken),
        error: {},
      };

    case VERIFY_OTP_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    case NEW_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case NEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case NEW_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    case SINGLE_TICKET_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case SINGLE_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        singleTicket: action.payload,
      };
    case SINGLE_TICKET_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    case EVENT_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        event: action.payload,
      };
    case EVENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    case SEAT_DETAIL_REQUEST:
      return {
        ...state,
        seatLoading: true,
        error: {},
      };
    case SEAT_DETAIL_SUCCESS:
      return {
        ...state,
        seatLoading: false,
        seat: action?.payload,
      };
    case SEAT_DETAIL_FAIL:
      return {
        ...state,
        seatLoading: false,
        error: action?.payload,
      };
    case SEAT_VERIFY_REQUEST:
      return {
        ...state,
        seatVerifyLoading: true,
        error: {},
      };
    case SEAT_VERIFY_SUCCESS:
      return {
        ...state,
        seatVerifyLoading: false,
        seatVerifySuccess: true,
        seatVerify: action?.payload,
      };
    case SEAT_VERIFY_FAIL:
      return {
        ...state,
        seatVerifyLoading: false,
        seatVerifySuccess: false,
        error: action?.payload,
      };
    case PAYMENT_REQUEST:
      return {
        ...state,
        paymentLoading: true,
        error: {},
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        paymentLoading: false,
        payment: action?.payload,
      };
    case PAYMENT_FAIL:
      return {
        ...state,
        paymentLoading: false,
        error: action?.payload,
      };
    case USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: {},
      };

    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        token: verifyToken(action.payload?.accessToken),
        isAuthenticated: true,
        error: {},
      };

    case USER_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    case AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
      };
    case SET_ORDER_ID:
      return {
        ...state,
        orderId: action.payload,
      };
    case CLEAR_ORDER_ID:
      return {
        ...state,
        orderId: null,
      };
    case SEAT_VERIFY_STATUS_FALSE:
      return {
        ...state,
        seatVerifySuccess: false,
      };
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        loading: false,
        otpSuccess: false,
        token: null,
        otpId: null,
        user: null,
        isAuthenticated: false,
        seatVerifySuccess: false,
        payment: null,
        event: null,
        seat: null,
        seatVerify: null,
      };
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        error: action?.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: {},
        otpSuccess: false,
        otpId: null,
        seatVerify: null,
        payment: null,
      };

    default:
      return state;
  }
};
