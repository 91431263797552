import { get } from 'lodash';
import React, { memo, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { mimNumber } from '../../../config/common';
//import { home } from "../../../data";
import CouponForm from './CouponForm';
import { addEventNewItem } from '../../../redux/actions/eventActions';
import { ArrowCircleRightIcon } from '@heroicons/react/solid';

const FirstComeFirstServe = ({
  eventInfo,
  //  event,
  addEventNewItem,
  handSubmit,
  totalPrice,
  totalPayAmount,
  totalAmount,
  bookingPercentage,
  //toggleDiscount,
  eventDiscountAmount,
  setEventCouponCode,
  setEventDiscountAmount,
  //handleViewTicket,
  SGST,
  CGST,
  convenienceFee,
}) => {
  const increasePerson = useCallback(
    (item) => {
      const newQty = item.quantity + 1;
      if (newQty > mimNumber(item?.maxSeats, item?.maxBookingsAllowed)) return;
      addEventNewItem({ code: item?.code, quantity: newQty });
    },
    [addEventNewItem],
  );

  const decreasePerson = useCallback(
    (item) => {
      const newQty = item.quantity - 1;
      if (newQty <= -1) return;
      addEventNewItem({ code: item?.code, quantity: newQty });
    },
    [addEventNewItem],
  );

  const [Open, setOpen] = useState(false);

  return eventInfo.eventType === 'FCFS' ? (
    <React.Fragment>
      <div className="flex w-full ">
        <div className="flex w-full flex-col items-center justify-center text-center ">
          {eventInfo.isSingletonEvent
            ? eventInfo?.ticketTypes?.map((item, index) =>
                item?.singleton ? (
                  <div
                    className="my-4 flex w-full flex-col items-start gap-2 rounded-lg border  border-gray-200 p-2 md:gap-4"
                    key={`220${index}`}
                  >
                    <div className="flex flex-col items-start ">
                      <p className="text-lg font-bold">{item?.name} </p>
                      {item?.desc && (
                        <p className="md:text-md text-left text-sm text-secondary md:text-center">({item?.desc})</p>
                      )}
                    </div>
                    <div className="mx-auto w-full border-b bg-gray-600" />

                    <div className="flex w-full items-center justify-between">
                      <div className="text-lg font-bold tracking-wide md:text-2xl">₹{item.price}/-</div>
                      <div>
                        <button
                          className="flex size-[25px] items-center justify-center rounded-[5px] bg-[#F2F4F7] px-3 py-1 text-[#000] md:size-[32px]"
                          onClick={() => {
                            decreasePerson(item);
                            setOpen(false);
                          }}
                          disabled={item?.singleton && item?.disabled}
                        >
                          <p className="m-0 p-0 text-sm  md:text-lg"> - </p>
                        </button>
                        <p className=" text-medium px-2 text-sm text-black md:text-lg">{item?.quantity}</p>
                        <button
                          className="flex  size-[25px] items-center justify-center rounded-[5px] bg-btnPrimary px-3 py-1 text-white md:size-[32px]"
                          onClick={() => {
                            increasePerson(item);
                            setOpen(true);
                          }}
                          disabled={item?.singleton && item?.disabled}
                        >
                          <p className="m-0 p-0 text-sm  md:text-lg"> + </p>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  Open && (
                    <div className="mb-3 flex w-full items-center justify-between md:items-start" key={`221${index}`}>
                      <div className="my-4 flex w-full flex-col items-start gap-2 rounded-lg border  border-gray-200 p-2 md:gap-4">
                        <div className="flex flex-col items-start ">
                          <p className="text-lg font-bold">{item?.name} </p>
                          {item?.desc && (
                            <p className=" text-left text-sm text-secondary md:text-center">({item?.desc})</p>
                          )}
                        </div>
                        <div className="mx-auto w-full border-b bg-gray-600" />
                        <div className="flex w-full items-center justify-between">
                          <div className="text-lg font-bold tracking-wide lg:text-xl">₹{item.price}/-</div>
                          <div>
                            <button
                              className="flex size-[25px] items-center justify-center rounded-[5px] bg-[#F2F4F7] px-3 py-1 text-[#000] md:size-[32px]"
                              onClick={() => {
                                decreasePerson(item);
                              }}
                              disabled={!item?.quantity === 0}
                            >
                              <p className="m-0 p-0 text-sm  md:text-lg"> - </p>
                            </button>

                            <p className="text-medium px-2 text-sm text-black md:text-lg">{item?.quantity}</p>
                            <button
                              className="flex  size-[25px] items-center justify-center rounded-[5px] bg-btnPrimary px-3 py-1 text-white md:size-[32px]"
                              onClick={() => {
                                increasePerson(item);
                              }}
                              disabled={item?.singleton && item?.disabled}
                            >
                              <p className="m-0 p-0 text-sm  md:text-lg"> + </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ),
              )
            : eventInfo?.ticketTypes?.map((item, index) => (
                <div
                  className="my-2 flex w-full flex-col items-start gap-2 rounded-lg border border-gray-200  p-2 md:gap-4 lg:my-4"
                  key={index}
                >
                  <div className="flex flex-col items-start ">
                    <p className="text-base font-bold lg:text-xl">{item?.name} </p>
                    {item?.desc && (
                      <p className="text-left text-sm text-secondary md:text-center lg:text-base">({item?.desc})</p>
                    )}
                  </div>
                  <div className="mx-auto w-full border-b bg-gray-600" />
                  <div className="flex w-full items-center justify-between">
                    <div className="text-base font-bold tracking-wide lg:text-xl">₹{item.price}/-</div>
                    {item?.maxSeats == 0 && (
                      <div className="flex items-center space-x-4">
                        <p className="rounded-lg bg-btnPrimary px-2 text-sm font-medium text-white lg:text-lg">
                          SOLD OUT
                        </p>
                      </div>
                    )}
                    {item?.maxSeats == -1 && (
                      <div className="flex items-center space-x-4">
                        <p className="rounded-lg bg-btnPrimary px-2 text-sm font-medium tracking-wide text-white lg:text-lg">
                          COMING SOON
                        </p>
                      </div>
                    )}

                    {item?.maxSeats > 0 && (
                      <div className="flex items-center space-x-4">
                        <button
                          className="flex size-[25px] items-center justify-center rounded-[5px] bg-[#F2F4F7] px-3 py-1 text-[#000] lg:size-[32px] "
                          onClick={() => {
                            decreasePerson(item);
                          }}
                          disabled={!item?.quantity === 0}
                        >
                          <p className="m-0 p-0 text-sm lg:text-lg"> - </p>
                        </button>

                        <p className="px-2 text-sm text-black lg:text-lg">{item?.quantity}</p>

                        <button
                          className="flex  size-[25px] items-center justify-center rounded-[5px] bg-btnPrimary px-3 py-1 text-white lg:size-[32px] "
                          onClick={() => {
                            increasePerson(item);
                          }}
                        >
                          <p className="m-0 p-0 text-sm  lg:text-lg"> + </p>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
        </div>
      </div>
      <div className="mt-32 hidden h-[550px] border-l border-gray-200  " />
      <div className="flex w-full items-center justify-center ">
        <div className="flex w-full flex-col gap-8 sm:gap-4 ">
          <div className="flex flex-col gap-4 sm:gap-2">
            <p className="text-lg font-bold lg:text-xl ">Order Summary</p>
            <div className="flex flex-col gap-2">
              {eventInfo?.ticketTypes?.map((item, index) => (
                <div key={index} className="flex justify-between text-sm text-secondary">
                  <p>
                    {item?.quantity}X {item?.name}
                  </p>
                  <p>₹{item?.quantity * item?.price}</p>
                </div>
              ))}
            </div>

            <div className="mx-auto w-full border-b bg-gray-600" />

            <div className="flex flex-col gap-2">
              <div className="flex justify-between truncate text-sm text-secondary">
                <p>Sub Total</p>
                <p>₹{totalPrice}</p>
              </div>
              {totalPrice !== 0 && (
                <>
                  {CGST !== 0 && CGST !== null && (
                    <div className="flex justify-between truncate text-sm text-secondary">
                      <p>CGST ({eventInfo?.cgstPercentage && eventInfo?.cgstPercentage + '%'}) : </p>
                      <p>₹ {CGST && CGST}</p>
                    </div>
                  )}
                  {SGST !== 0 && SGST !== null && (
                    <div className="flex justify-between truncate text-sm text-secondary">
                      <p>SGST ({eventInfo?.sgstPercentage && eventInfo?.sgstPercentage + '%'}) : </p>
                      <p>₹ {SGST && SGST}</p>
                    </div>
                  )}
                  {convenienceFee !== 0 && convenienceFee !== null && (
                    <div className="flex justify-between truncate text-sm text-secondary">
                      <p>Convenience Fee</p>
                      <p>₹ {convenienceFee}</p>
                    </div>
                  )}
                  {bookingPercentage !== 0 && bookingPercentage !== null && (
                    <div className="flex justify-between truncate text-sm text-secondary">
                      <p>Booking Charges</p>
                      <p>₹ {bookingPercentage}</p>
                    </div>
                  )}
                </>
              )}
              {/* <div className="flex justify-between text-sm text-secondary">
                  <p>Taxes</p>
                  <p>₹{taxes.toFixed(2)}</p>
                </div> */}
              {eventDiscountAmount > 0 && (
                <div className="flex justify-between text-sm text-[#039855]">
                  <p className=" text-[#039855]">Discount</p>
                  <p className=" text-[#039855]">-₹{eventDiscountAmount.toFixed(2)}</p>
                </div>
              )}
            </div>

            <div className="mx-auto w-full border-b bg-gray-600" />
            {totalPrice == 0 && (
              <div className="flex justify-between truncate text-lg font-bold text-black lg:text-xl">
                <p>Total</p>
                <p>₹0.00</p>
              </div>
            )}
            {totalPrice !== 0 && (
              <div className="flex justify-between truncate text-lg font-bold text-black lg:text-xl">
                <p>Total</p>
                <p>₹{totalPayAmount.toFixed(2)}</p>
              </div>
            )}
          </div>

          <CouponForm
            total={totalAmount}
            couponData={eventInfo?.eventCoupons}
            onDiscountApply={setEventDiscountAmount}
            onCouponApply={setEventCouponCode}
          />
          <button onClick={handSubmit} className="w-full rounded-xl bg-btnPrimary px-6 py-2 text-white ">
            <div className="flex items-center justify-center gap-2">
              <p className="text-nowrap text-base font-medium text-white"> Proceed to Checkout</p>
              <ArrowCircleRightIcon className="size-6 text-white" />
            </div>
          </button>
        </div>
      </div>

      {/* <div className="flex w-full justify-end py-4 md:py-2"> */}
      {/* {event?.bookingDone && (
          <button
            className="m-2 rounded-full  px-5 py-2.5 text-center text-sm font-medium text-white  border border-solid border-red-500 bg-red-500 text-white hover:bg-transparent hover:text-red-500"
            onClick={handleViewTicket}
          >
            {home.button3}
          </button>
        )} */}
      {/* {totalPrice !== 0 && totalPayAmount !== 0 && (
          <button
            onClick={handSubmit}
            className="m-2 rounded-full  px-5 py-2.5 text-center text-sm font-medium text-white  border border-solid border-red-500 bg-red-500 text-white hover:bg-transparent hover:text-red-500"
          >
            Pay ₹ {totalPayAmount}
          </button>
        )} */}
      {/* </div> */}
    </React.Fragment>
  ) : eventInfo?.eventType === 'FREE_FCFS' ? (
    <React.Fragment>
      <div className="flex w-full ">
        <div className="flex w-full flex-col items-center justify-center text-center ">
          {eventInfo?.ticketTypes?.map((item, index) => (
            <div
              className="my-4 flex w-full flex-col items-start gap-2 rounded-lg border  border-gray-200 p-2 md:gap-4"
              key={index}
            >
              <div className="flex flex-col items-start ">
                <p className="text-lg font-bold">{item?.name} </p>
                {item?.desc && (
                  <p className="md:text-md text-left text-sm text-secondary md:text-center">({item?.desc})</p>
                )}
              </div>
              <div className="mx-auto w-full border-b bg-gray-600" />
              <div className="flex w-full items-center justify-between">
                <div className="text-lg font-bold tracking-wide md:text-2xl">FREE</div>
                <div className="flex items-center space-x-4">
                  <button
                    className="flex size-[25px] items-center justify-center rounded-[5px] bg-[#F2F4F7] px-3 py-1 text-[#000] md:size-[32px] "
                    onClick={() => {
                      decreasePerson(item);
                    }}
                    disabled={!item?.quantity === 0}
                  >
                    <p className="m-0 p-0 text-sm md:text-lg"> - </p>
                  </button>

                  <p className="text-medium px-2 text-sm text-black md:text-lg">{item?.quantity}</p>

                  <button
                    className="flex  size-[25px] items-center justify-center rounded-[5px] bg-btnPrimary px-3 py-1 text-white md:size-[32px] "
                    onClick={() => {
                      increasePerson(item);
                    }}
                  >
                    <p className="m-0 p-0 text-sm  md:text-lg"> + </p>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-32 hidden h-[550px] border-l border-gray-200  " />
      <div className="flex w-full items-center justify-center ">
        <div className="flex w-full flex-col gap-8 sm:gap-4 ">
          <div className="flex flex-col gap-4 sm:gap-2">
            <p className="text-xl font-bold ">Order Summary</p>
            <div className="flex flex-col gap-2">
              {eventInfo?.ticketTypes?.map((item, index) => (
                <div key={index} className="flex justify-between text-sm text-secondary">
                  <p>
                    {item?.quantity}X {item?.name}
                  </p>
                  <p>FREE</p>
                </div>
              ))}
            </div>

            <div className="mx-auto w-full border-b bg-gray-600" />

            <div className="flex flex-col gap-2">
              <div className="flex justify-between truncate text-sm text-secondary">
                <p>Sub Total</p>
                <p>FREE</p>
              </div>
              {totalPrice !== 0 && (
                <>
                  {CGST !== 0 && CGST !== null && (
                    <div className="flex justify-between truncate text-sm text-secondary">
                      <p>CGST ({eventInfo?.cgstPercentage && eventInfo?.cgstPercentage + '%'}) : </p>
                      <p>₹ {CGST && CGST}</p>
                    </div>
                  )}
                  {SGST !== 0 && SGST !== null && (
                    <div className="flex justify-between truncate text-sm text-secondary">
                      <p>SGST ({eventInfo?.sgstPercentage && eventInfo?.sgstPercentage + '%'}) : </p>
                      <p>₹ {SGST && SGST}</p>
                    </div>
                  )}
                  {convenienceFee !== 0 && convenienceFee !== null && (
                    <div className="flex justify-between truncate text-sm text-secondary">
                      <p>Convenience Fee</p>
                      <p>₹ {convenienceFee}</p>
                    </div>
                  )}
                  {bookingPercentage !== 0 && bookingPercentage !== null && (
                    <div className="flex justify-between truncate text-sm text-secondary">
                      <p>Booking Charges</p>
                      <p>₹ {bookingPercentage}</p>
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="mx-auto w-full border-b bg-gray-600" />

            <div className="flex justify-between truncate text-2xl font-bold text-black">
              <p>Total</p>
              <p>FREE</p>
            </div>
          </div>

          <button onClick={handSubmit} className="w-full rounded-xl bg-btnPrimary px-6 py-2 text-white ">
            <div className="flex items-center justify-center gap-2">
              <p className="font-500 text-white"> Proceed to Checkout</p>
              <ArrowCircleRightIcon className="size-6 text-white" />
            </div>
          </button>
        </div>
      </div>
      {/* <div className="flex w-full justify-end py-4 md:py-2">
        {event?.bookingDone && (
          <button
            className="m-2 rounded-full  px-5 py-2.5 text-center text-sm font-medium text-white  border border-solid border-red-500 bg-red-500 text-white hover:bg-transparent hover:text-red-500"
            onClick={handleViewTicket}
          >
            {home.button3}
          </button>
        )}
        {totalPrice !== 0 && totalPayAmount !== 0 && (
          <button
            onClick={handSubmit}
            className="m-2 rounded-full  px-5 py-2.5 text-center text-sm font-medium text-white  border border-solid border-red-500 bg-red-500 text-white hover:bg-transparent hover:text-red-500"
          >
            Book Now
          </button>
        )}
      </div> */}
    </React.Fragment>
  ) : null;
};
const mapStateToProps = (state) => ({
  event: get(state, 'auth.event', {}),
  eventInfo: get(state, 'event.eventInfo', {}),
});

export default connect(mapStateToProps, { addEventNewItem })(memo(FirstComeFirstServe));
