import { MdEmail, MdPhone } from 'react-icons/md';
import { FaFacebook, FaLinkedinIn, FaYoutube, FaInstagram } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import logo from '../../Images/curiobit/logo-light.png';

const Footer = ({ demo }) => {
  return (
    <div className="bg-[#093338] ">
      <div className=" mx-auto flex w-full max-w-[1300px] flex-col items-start justify-between gap-8 px-10 py-8 md:flex-row md:gap-0">
        <div className="flex flex-col gap-2">
          <img src={logo} alt="logo" className="h-[30px] w-[120px]" />
          <p className="w-[100%] font-montserrat text-sm font-normal leading-6 tracking-wide text-white">
            A bit of curiosity…
          </p>
        </div>
        <div className="flex w-[60%] flex-col items-start justify-end gap-0 md:flex-row md:gap-10 ">
          <div className="flex flex-col  justify-start text-left">
            <h2 className="text-lg font-semibold text-white ">Get In Touch</h2>
            <div className="flex flex-row items-center gap-1 py-3">
              <MdEmail className="size-5 text-white" size={60} />
              <p className="text-sm text-[#77808B]">curiobit.ai@gmail.com</p>
            </div>
            <div className="flex flex-row items-center gap-1">
              <MdPhone className="size-5 text-white" />
              <p className="text-sm text-[#77808B]">7653012645</p>
            </div>
            {!demo && (
              <div className="hidden flex-col gap-2 md:flex lg:hidden">
                <h2 className="pb-2 text-lg font-semibold text-white">Navigate</h2>
                <div className="flex flex-row items-center gap-2">
                  <p className="text-sm text-[#77808B]">Home</p>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <p className="text-sm text-[#77808B]">Features</p>
                </div>
                <div className="flex flex-row items-center gap-2">
                  <p className="text-sm text-[#77808B]">Topics</p>
                </div>
              </div>
            )}
          </div>
          {!demo && (
            <div className="flex flex-col gap-2 md:hidden lg:flex">
              <h2 className="pb-2 text-lg font-semibold text-white">Navigate</h2>
              <div className="flex flex-row items-center gap-2">
                <p className="text-sm text-[#77808B]">Home</p>
              </div>
              <div className="flex flex-row items-center gap-2">
                <p className="text-sm text-[#77808B]">Features</p>
              </div>
              <div className="flex flex-row items-center gap-2">
                <p className="text-sm text-[#77808B]">Topics</p>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row items-start gap-6">
          <div className="flex flex-col gap-2">
            <h2 className="pb-2 text-lg font-semibold text-white">Follow us</h2>
            <div className="flex flex-row items-center gap-5">
              <Link
                className="flex size-10 flex-col items-center justify-center rounded-full bg-white/20 text-white"
                to={'https://www.facebook.com/profile.php?id=61559538353453&mibextid=rS40aB7S9Ucbxw6v'}
              >
                <FaFacebook className="scale-100" size={22} />
              </Link>
              <Link
                className="flex size-10 flex-col items-center justify-center rounded-full bg-white/20 text-white"
                to={'https://www.youtube.com/@CuriobitAi'}
              >
                <FaYoutube className="scale-100" size={22} />
              </Link>
              <Link
                className="flex size-10 flex-col items-center justify-center rounded-full bg-white/20 text-white"
                to={'https://www.linkedin.com/company/curiobit-ai'}
              >
                <FaLinkedinIn className="scale-100" size={22} />
              </Link>
              <Link
                className="flex size-10 flex-col items-center justify-center rounded-full bg-white/20 text-white"
                to={'https://www.instagram.com/curiobit.ai/'}
              >
                <FaInstagram className="scale-100" size={22} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center border border-gray-200 px-10" />
      <p className="hidden justify-center py-6 text-sm font-bold text-[#8D8D8D] md:flex">
        &copy; Copyright 2024 Curiobit Technologies Private Limited | All rights reserved
      </p>
      <p className="flex w-[100%] justify-center py-6 text-center text-sm font-bold text-[#8D8D8D] md:hidden">
        &copy; Copyright 2024 Curiobit Technologies Private <br />
        Limited | All rights reserved
      </p>
    </div>
  );
};

export default Footer;
