import React, { useRef, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Autocomplete, LoadScript, GoogleMap, MarkerF } from '@react-google-maps/api';
import TextField from '../../utils/TextField';
import { connect } from 'react-redux';
import { get } from 'lodash';

const AddressDialog = ({ isOpen, eventInfo, handleClose, onAddressSubmit, user }) => {
  const LIBRARIES = ['places'];

  const cancelButtonRef = useRef(null);
  const autocompleteRef = useRef(null);
  const [location, setLocation] = useState({
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    lat: 20.2961,
    lng: 85.8245,
    searchLocation: '',
  });

  const INITIAL_FORM_STATE = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    lat: 20.2961,
    lng: 85.8245,
    searchLocation: '',
  };

  const FORM_VALIDATION = Yup.object().shape({
    addressLine1: Yup.string().required('Flat No, Block No is required'),
    addressLine2: Yup.string().required('Apartment Name, Landmark is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    country: Yup.string().required('Country is required'),
    pincode: Yup.string().required('Pincode is required'),
    searchLocation: Yup.string().required('Search location is required'),
  });

  const handlePlaceSelect = (place, setFieldValue) => {
    const addressComponents = place.address_components || [];
    const lat = place.geometry?.location?.lat();
    const lng = place.geometry?.location?.lng();

    const formattedAddress = {
      addressLine1: '',
      addressLine2: '',
      city: getAddressComponent(addressComponents, 'locality'),
      state: getAddressComponent(addressComponents, 'administrative_area_level_1'),
      country: getAddressComponent(addressComponents, 'country'),
      pincode: getAddressComponent(addressComponents, 'postal_code'),
      lat: lat || 0,
      lng: lng || 0,
      searchLocation: place.name || '',
    };

    setFieldValue('searchLocation', formattedAddress.searchLocation);
    setFieldValue('city', formattedAddress.city);
    setFieldValue('state', formattedAddress.state);
    setFieldValue('country', formattedAddress.country);
    setFieldValue('pincode', formattedAddress.pincode);

    setLocation(formattedAddress);
  };

  const getAddressComponent = (components, type) => {
    const component = components.find((c) => c.types.includes(type));
    return component ? component.long_name : '';
  };

  const submitAnswers = async (values) => {
    const { name, mobile } = user || {};

    const newData = eventInfo?.extraQuestions.map((item) => {
      return {
        questionCode: item.questionCode,
        questionText: item.questionText,
        answerType: item.answerType,
        // Store the relevant location fields instead of 'select'
        answer: {
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          city: values.city,
          state: values.state,
          country: values.country,
          pincode: values.pincode,
          lat: values.lat,
          lng: values.lng,
          searchLocation: values.searchLocation,
          name: name || '', // Include the name
          mobile: mobile || '',
        },
      };
    });
    onAddressSubmit(newData);
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''} libraries={LIBRARIES}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={isOpen}
        onClose={handleClose}
      >
        <div className="flex min-h-screen items-center justify-center p-0 px-4 pb-20 pt-4">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <div className="relative w-full max-w-2xl p-4 md:h-auto">
            <div className="relative rounded-lg bg-white shadow">
              <button
                type="button"
                className="absolute right-2.5 top-3 ml-auto inline-flex items-center rounded-full bg-transparent p-1.5 text-lg text-red-400 hover:bg-gray-200 hover:text-gray-900"
                onClick={handleClose}
              >
                <svg
                  aria-hidden="true"
                  className="size-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="p-6 lg:px-8">
                <Formik
                  initialValues={INITIAL_FORM_STATE}
                  validationSchema={FORM_VALIDATION}
                  onSubmit={async (values, { resetForm }) => {
                    console.log(values);
                    await submitAnswers(values);
                    resetForm({ values: INITIAL_FORM_STATE });
                    handleClose();
                  }}
                >
                  {({ values, handleChange, setFieldValue }) => (
                    <Form className="space-y-6">
                      <Autocomplete
                        onLoad={(autocomplete) => {
                          autocomplete.setComponentRestrictions({ country: 'IN' });
                          autocompleteRef.current = autocomplete;
                        }}
                        onPlaceChanged={() => {
                          if (autocompleteRef.current) {
                            const selectedPlace = autocompleteRef.current.getPlace();
                            handlePlaceSelect(selectedPlace, setFieldValue);
                          }
                        }}
                      >
                        <TextField
                          name="searchLocation"
                          label="Search Location"
                          placeholder="Enter a location"
                          value={values.searchLocation}
                          onChange={handleChange}
                        />
                      </Autocomplete>

                      {/* Map and Marker */}
                      <div className="map-container mb-4">
                        <GoogleMap
                          center={{ lat: location.lat, lng: location.lng }}
                          zoom={15}
                          mapContainerStyle={{ height: '300px', width: '100%' }}
                        >
                          {location.lat && location.lng && (
                            <MarkerF position={{ lat: location.lat, lng: location.lng }} />
                          )}
                        </GoogleMap>
                      </div>

                      <TextField
                        name="addressLine1"
                        label="Flat No, Block No"
                        value={values.addressLine1}
                        onChange={handleChange}
                      />
                      <TextField
                        name="addressLine2"
                        label="Apartment Name, Landmark"
                        value={values.addressLine2}
                        onChange={handleChange}
                      />
                      <TextField name="city" label="City" value={values.city} onChange={handleChange} />
                      <TextField name="state" label="State" value={values.state} onChange={handleChange} />
                      <TextField name="country" label="Country" value={values.country} onChange={handleChange} />
                      <TextField name="pincode" label="Pincode" value={values.pincode} onChange={handleChange} />
                      <button
                        type="submit"
                        style={{
                          background: 'linear-gradient(90deg, #F9D730 0%, #FDBE0F 44.16%, #FFB200 100%)',
                        }}
                        className="w-full rounded-lg px-4 py-2 text-center font-montserrat text-xl font-bold text-[#1A1A1A] hover:bg-[#FFB219] focus:outline-none "
                      >
                        Submit
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </LoadScript>
  );
};

// export default AddressDialog;
const mapStateToProps = (state) => ({
  user: get(state, 'auth.user', null),
});
export default connect(mapStateToProps)(AddressDialog);
